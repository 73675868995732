/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../utils/axios';
import Page from '../../components/Layout/Page';
import './Cart.css';
import { logout } from '../../actions/accountActions';
import CartList from '../../components/Cart/CartList';
import CartTotals from './totals/CartTotals';
import CartCoupon from './coupon/CartCoupon';
import CartBilling from './billing/CartBilling';
import CartPaymentMethod from './payment/CartPayment';
import CartSubmit from './submit/CartSubmit';
import Recommendations from './recommendations/Recommendations';

import {
  PAYMENT_TYPE_RAPPI,
  PAYMENT_TYPE_CORNERSHOP,
  PAYMENT_TYPE_UBEREATS,
  PAYMENT_TYPE_PEDIDOSYA,
  // PAYMENT_TYPE_EFECTIVO,
  // PAYMENT_TYPE_TRANSFERENCIA,
  PAYMENT_TYPE_WALLET,
  BUY_TYPE_RAPPI,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_SOCIO,
  BUY_TYPE_RAPPI_TURBO,
  PAYMENT_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  PAYMENT_TYPE_GOODMEAL,
  BUY_TYPE_RAPPI_PAYLESS,
  PAYMENT_TYPE_RAPPI_PAYLESS,
  STATUS_BILLING_PROCESSING,
} from '../../utils/const';

import {
  setApplyBulk,
  setSubtotal,
  setTotal,
  updatePayment,
  updateCartProduct,
  setAllDiscounts,
  setCartLoading,
} from '../../actions/customerActions';
import { calculateDiscounts } from '../../utils/getDiscounts';
import { customerDiscountDay } from '../../utils/generalDiscount';

function Cart() {
  const dispatch = useDispatch();

  const documentType = useSelector((state) => state.customer.document_type);
  const cart = useSelector((state) => state.customer.cart);
  const store = useSelector((state) => state.account.user.Store);
  const customer = useSelector((state) => state.customer);
  const { user } = useSelector((state) => state.account);
  const buyType = useSelector((state) => state.customer.type);
  // const bulk = useSelector((state) => state.customer.bulk);
  const subtotal = useSelector((state) => state.customer.subtotal);
  const coupon = useSelector((state) => state.customer.coupon);
  const couponDiscount = useSelector((state) => state.customer.coupon_discount);
  const generalDiscount = useSelector((state) => state.customer.general_discount);
  const total = useSelector((state) => state.customer.total);
  const payments = useSelector((state) => state.customer.payments);
  const recommendedProduct = useSelector((state) => state.customer.recommended_product);
  const allQuantities = useSelector((state) => state.customer.all_quantities);
  // const cartLoading = useSelector((state) => state.customer.cart_loading);
  const { machineNumber } = useSelector((state) => state.session);

  const [totalPoints, setTotalPoints] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [customerOrders, setCustomerOrders] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState('');
  const [recommendationsOpen, setRecommendationsOpen] = useState(false);

  // Handle logout
  const clickLogout = () => {
    dispatch(logout());
  };

  // Handle scroll
  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 90;
      if (scrollCheck !== scrolled) {
        setScrolled(scrollCheck);
      }
    });
  }, [window.scrollY]);

  // Get basic info
  useEffect(() => {
    dispatch(setCartLoading(true));
    const getCustomerOrders = async () => {
      if (buyType === BUY_TYPE_SOCIO) {
        if (customerDiscountDay()) {
          const response = await axios.get(`/api/orders/customer/${customer.user.id}`);
          const { orders } = response.data.data;
          setCustomerOrders(orders);
        } else {
          setCustomerOrders([]);
        }
      }
    };

    const getCustomerPoints = async () => {
      if (!customer.user) {
        setTotalPoints(0);
      } else if (!customer.user.id) {
        setTotalPoints(0);
      } else {
        const pointsResponse = await axios.get(
          `/api/customers/${customer.user.id}/points`,
        );
        setTotalPoints(
          pointsResponse.data.points
          + pointsResponse.data.cash
          - pointsResponse.data.reserved,
        );
      }
    };
    getCustomerOrders();
    getCustomerPoints();
  }, []);

  // Check payments
  useEffect(() => {
    if (payments.length === 1) {
      if (buyType === BUY_TYPE_RAPPI) {
        const payment = payments[0];
        dispatch(updatePayment({
          id: payment.id,
          payment_type: PAYMENT_TYPE_RAPPI,
          amount: total,
        }));

        return;
      }

      if (buyType === BUY_TYPE_RAPPI_PAYLESS) {
        const payment = payments[0];
        dispatch(updatePayment({
          id: payment.id,
          payment_type: PAYMENT_TYPE_RAPPI_PAYLESS,
          amount: total,
        }));

        return;
      }

      if (buyType === BUY_TYPE_RAPPI_TURBO) {
        const payment = payments[0];
        dispatch(updatePayment({
          id: payment.id,
          payment_type: PAYMENT_TYPE_RAPPI_TURBO,
          amount: total,
        }));

        return;
      }

      if (buyType === BUY_TYPE_CORNERSHOP) {
        const payment = payments[0];
        dispatch(updatePayment({
          id: payment.id,
          payment_type: PAYMENT_TYPE_CORNERSHOP,
          amount: total,
        }));

        return;
      }

      if (buyType === BUY_TYPE_UBEREATS) {
        const payment = payments[0];
        dispatch(updatePayment({
          id: payment.id,
          payment_type: PAYMENT_TYPE_UBEREATS,
          amount: total,
        }));

        return;
      }

      if (buyType === BUY_TYPE_PEDIDOSYA) {
        const payment = payments[0];
        dispatch(updatePayment({
          id: payment.id,
          payment_type: PAYMENT_TYPE_PEDIDOSYA,
          amount: total,
        }));

        return;
      }

      if (buyType === BUY_TYPE_GOODMEAL) {
        const payment = payments[0];
        dispatch(updatePayment({
          id: payment.id,
          payment_type: PAYMENT_TYPE_GOODMEAL,
          amount: total,
        }));

        return;
      }

      const payment = payments[0];

      if (payment.payment_type === PAYMENT_TYPE_WALLET) {
        dispatch(updatePayment({
          id: payment.id,
          payment_type: payment.payment_type,
          amount: totalPoints - total < 0 ? totalPoints : total,
        }));

        return;
      }

      dispatch(updatePayment({
        id: payment.id,
        payment_type: payment.payment_type,
        amount: total,
      }));
    }
  }, [payments.length, total]);

  // Set payment types
  useEffect(() => {
    const newPaymentTypes = payments.map((p) => p.payment_type).join();
    setPaymentTypes(newPaymentTypes);
  }, [payments]);

  // Apply general discount
  useEffect(() => {
    // Apply bulk
    const allowBulk = true;

    /* Allow all payment methods for bulk prices */
    // for (let i = 0; i < payments.length; i++) {
    //   const p = payments[i];
    //   if (p.payment_type !== PAYMENT_TYPE_EFECTIVO
    //     && p.payment_type !== PAYMENT_TYPE_TRANSFERENCIA
    //     && p.payment_type !== PAYMENT_TYPE_WALLET
    //   ) {
    //     allowBulk = false;
    //   }
    // }

    dispatch(setApplyBulk(allowBulk));

    let newCart = {};
    const getDiscounts = async () => {
      dispatch(setCartLoading(true));
      newCart = await calculateDiscounts(
        customerOrders || [],
        cart,
        false,
        allowBulk,
        buyType,
        recommendedProduct || [],
        store,
        customer,
        payments,
        coupon,
      );
      dispatch(setAllDiscounts(newCart.all_discounts));
      dispatch(setSubtotal(newCart.subtotal));
      dispatch(setTotal(newCart.total));
      Object.keys(newCart.cart).forEach((id) => {
        dispatch(updateCartProduct(newCart.cart[id]));
      });
      dispatch(setCartLoading(false));
    };

    if (buyType === BUY_TYPE_SOCIO) {
      if (customerOrders !== null) getDiscounts();
    } else {
      getDiscounts();
    }
  }, [
    customerOrders,
    paymentTypes,
    // bulk,
    coupon,
    allQuantities,
  ]);

  if (!machineNumber && !window.AppPagoSDK) {
    return (
      <Redirect to="maquina" />
    );
  }

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | POS Compra"
      progressStep="compra"
      backRef="/compra"
    >
      <div className="cart-container">
        <div className="header">
          <p>{`Vendedor: ${user.name}`}</p>
          <button
            type="button"
            className="logout"
            onClick={() => clickLogout()}
          >
            Cerrar Sesión
          </button>
        </div>
        <div className="title">
          <img src="img/cart.png" alt="carrito" />
          <h3>Carrito de Compras</h3>
        </div>
        <CartList
          scrolled={scrolled}
          cart={cart}
          bulk={false}
          buyType={buyType}
          priceList={store.price_list_id}
          clubPriceList={store.club_price_list_id}
          bulkPriceList={store.bulk_price_list_id}
          rappiPriceList={store.rappi_price_list_id}
          rappiTurboPriceList={store.rappi_turbo_price_list_id}
          cornershopPriceList={store.ubereats_price_list_id}
          ubereatsPriceList={store.ubereats_price_list_id}
          pedidosyaPriceList={store.pedidosya_price_list_id}
          goodmealPriceList={store.goodmeal_price_list_id}
        />
        <CartTotals
          buyType={buyType}
          subtotal={subtotal}
          couponDiscount={couponDiscount}
          generalDiscount={generalDiscount}
          total={total}
          totalPoints={totalPoints}
        />
        {((buyType !== BUY_TYPE_RAPPI
          && buyType !== BUY_TYPE_RAPPI_TURBO
          && buyType !== BUY_TYPE_RAPPI_PAYLESS
          && buyType !== BUY_TYPE_CORNERSHOP
          && buyType !== BUY_TYPE_UBEREATS
          && buyType !== BUY_TYPE_PEDIDOSYA
          && buyType !== BUY_TYPE_GOODMEAL)
          && (
            <div className="bottom-info">
              {store.recommended_active ? (
                <div className="button-holder">
                  <button
                    type="button"
                    onClick={() => setRecommendationsOpen(true)}
                    className="transparent-button-recommendations"
                  >
                    Mostrar recomendados
                  </button>
                </div>
              ) : null}
              <CartCoupon
                orders={customerOrders}
                customer={customer}
                coupon={coupon}
                bulk={false}
                buyType={buyType}
                priceList={store.price_list_id}
                clubPriceList={store.club_price_list_id}
                bulkPriceList={store.bulk_price_list_id}
                cart={cart}
                payments={payments}
              />
              <CartBilling
                documentType={documentType}
              />
              <CartPaymentMethod
                points={totalPoints}
                total={total}
                payments={payments}
              />
            </div>
          )
        )}
        <CartSubmit
          documentType={documentType}
          cart={cart}
          store={store}
          customer={customer}
          user={user}
          coupon={coupon}
          buyType={buyType}
          payments={payments}
          total={total}
        />
        <Recommendations
          reOpen={recommendationsOpen}
          setReOpen={setRecommendationsOpen}
        />
      </div>
    </Page>
  );
}

export default Cart;
